<template>
  <div>
    <a-card class="mb-4">
      <div class="d-flex mb-4">
        <span class="h5 mb-0"><a-icon type="project"/> Binancio</span>
      </div>

      <a-form layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              label="Криптовалюта"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input placeholder="Криптовалюта" v-model="ticker"/>
            </a-form-item>
            <a-form-item
              label="Валюта рассчета"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input placeholder="Валюта расчета" v-model="fiat"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="Операция"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input placeholder="Операция" v-model="operation"/>
            </a-form-item>
            <a-form-item
              label="Банк"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input placeholder="Банк" v-model="payTypes"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="Сумма"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input placeholder="Сумма" v-model="transAmount"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item :wrapper-col="buttonItemLayout.wrapperCol">
          <a-button type="primary" @click="submit" :loading="loading">
            Расчитать
          </a-button>
          <span class="ml-3" v-if="cost !== 0">Стоимость: {{cost}}</span>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>

import apiClient from '@/services/axios'

export default {
  name: 'index',
  // mixins: [psMix],
  data() {
    return {
      ticker: 'USDT',
      fiat: 'RUB',
      operation: 'BUY',
      payTypes: 'Tinkoff',
      transAmount: '1000',
      cost: 0,
      loading: false,
    }
  },
  computed: {
    formItemLayout() {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        }
        : {}
    },
    buttonItemLayout() {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          wrapperCol: { span: 14, offset: 4 },
        }
        : {}
    },
  },
  methods: {
    async submit() {
      this.loading = true
      const data = {
        ticker: this.ticker,
        fiat: this.fiat,
        operation: this.operation,
        payTypes: this.payTypes,
        transAmount: this.transAmount,
      }

      const url = '/admin/binancio'
      apiClient.post(url, data).then((response) => {
        this.loading = false
        this.cost = response.data.data.data[0].adv.price
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>
<style>
.account-status-dot .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}

.ant-progress-text {
  font-size: 11px !important;
  font-weight: bolder;
}
</style>
<style scoped>
.ant-progress-text {
  font-size: 10px;
}
</style>
